<template>
  <div class="container-statement container-fluid mt--6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-sm-12 col-md-2 text-center mb-2">
            <el-select v-model="form.bankholderid" placeholder="BankholderID" class="form-control-alternative" @change="getBankAccountDetail">
              <el-option v-for="item of bankAccount" :key="item.uid" :label="`${item.currency} (${item.cardholderid})`" :value="item.cardholderid"></el-option>
            </el-select>
          </div>
          <div class="col-sm-12 col-md-2 text-center mb-2">
            <el-select v-model="form.month" placeholder="Month" class="form-control-alternative" >
              <el-option v-for="month of months" :key="month.id" :label="month.name" :value="month.id"></el-option>
            </el-select>
          </div>
          <div class="col-sm-12 col-md-2 text-center mb-2">
            <el-select v-model="form.year" placeholder="Year" class="form-control-alternative" >
              <el-option v-for="year of years" :key="year" :label="year" :value="year"></el-option>
            </el-select>
          </div>
          <div class="col-sm-12 col-md-2 text-center">
            <el-button class="btn btn-primary" v-loading="submiting" :disabled="submiting" @click="onSubmit()">Query</el-button>
          </div>
          <div class="col-sm-12 col-md-8">
            <div class="text-xs bg-light-gray p-3" v-if="detailBankAccount != ''" v-html="detailBankAccount"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9">
        <div class="card mb-3">
          <div class="card-header">
                <h3 class="mb-0">Statement</h3>
                <a href="javascript:void(0)" v-if="isMobile" v-loading="sending" class="btn btn-warning btn-block" @click="reportDownload('pdf')">Send to email</a>
              </div>

          <div class="card-body">
            <el-table 
            v-if="!isMobile" 
            :data="tableData"
            row-key="uid" 
            border style="width: 100%" 
            size="mini"
             lazy
            :load="loadDetailTransaction"
            :tree-props="{children: 'children', hasChildren: 'isReceive'}"
            >
              <el-table-column prop="date" label="Date" header-align="center" align="right" width="160"></el-table-column>
              <el-table-column prop="description" min-width="200" class-name="line-break" label="Description"></el-table-column>
              <el-table-column prop="credit" label="Credit" header-align="center" align="right" width="100"></el-table-column>
              <el-table-column prop="debit" label="Debit" header-align="center" align="right" width="100"></el-table-column>
              <el-table-column prop="balance" label="Balance" header-align="center" align="right" width="100"></el-table-column>
            </el-table>

            <div class="list-group m--2" v-if="isMobile">
              <div 
                class="list-group-item"
                v-for="(item, index) of tableData" :key="index"
                >
                
                <div class="d-flex w-100 justify-content-between">
                  <small class="statement-date">{{ item.date }}</small>
                  <span class="badge badge-success badge-pill justify-content-end" v-if="item.credit && item.credit!='0.00'">{{item.credit}}</span>
                  <span class="badge badge-danger badge-pill justify-content-end" v-if="item.debit && item.debit!='0.00'">-{{item.debit}}</span>
                </div>
                <div class="statement-description">{{ item.description}}</div>
                <div class="badge badge-primary badge-pill">
                  {{ (item.fee ? 'Fee '+item.fee : '') }}
                  <br v-if="item.fee" />
                  Bal {{ item.balance }}
                </div>
                
              </div>
    
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3" v-if="!isMobile" >
        <div class="card mb-3">
          <div class="card-body">
            <a href="javascript:void(0)" class="btn btn-success btn-block" @click="reportDownload('excel')">Download Excel</a>
            <a href="javascript:void(0)" class="btn btn-warning btn-block" @click="reportDownload('pdf')">Download PDF</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils'
import StatementApi from '@/api/statement';
import { isMobile } from "@/utils/helpers";
export default {
  name:"statement",
  computed: {
    isMobile() {
      return isMobile()
    },
  },
  data() {
    return {
      sending: false,
      submiting: false,
      bankAccount:[],
      months: Utils.monthList,
      years: Utils.yearList(),
      form: {
        bankholderid: "",
        month: Utils.getCurrentDate('MM'),
        year: Utils.getCurrentDate('YYYY'),
      },
      tableData: [],
      detailBankAccount: ""
    };
  },
  watch: {
            '$route.query.bankholderid'() {
              this.getQuery()
            }
        },
  methods: {
    async getBankAccount(){
      this.bankAccount = await Utils.getBankAccount()
    },
    onSubmit(){
      this.onQuery()
      if(this.$route.query && this.$route.query.bankholderid){
        this.$router.push(this.$route.path)
      }
    },
    onQuery() {
      this.submiting = true
      StatementApi.getStatement(this.form).then(({result, data})=>{
        this.submiting = false
        if(result){
          this.tableData = data.statement
        }
      })
    },
    renderSenderInfo(data){
        let senderInfo = ''
        if(data.type === "SCAN"){
          senderInfo = `Name: ${data.name} Account number: ${data.accountNumber} Sortcode: ${data.sortcode} Type: ${data.type}`
        }else{
          senderInfo = `Name: ${data.name} IBAN: ${data.iban} BIC: ${data.bic} Type: ${data.type}`
        }
        return senderInfo
    },
    loadDetailTransaction(tree, treeNode, resolve){
      let uid = tree.uid
      StatementApi.getSenderInfo(uid).then(({result, data}) => {
        if(result && data){
          let children = [
            {
              uid: Utils.uuid(),
              description: this.renderSenderInfo(data)
            }
          ]
          resolve(children)
        }else{
          resolve([])
        }
          
      })
    },
    reportDownload(type){
      if(this.isMobile){
        this.sending = true
        StatementApi.sendStatement(this.form).then(({result, message})=>{
          this.sending = false
          if(result){
            this.$swal('Message', message, 'success')     
          }else{
            this.$swal('Message', message, 'error')
          }
        })
      }else{
        StatementApi.export(type, this.form)
      }
    },
    getQuery(){
      if(this.$route.query && this.$route.query.bankholderid){
        this.form.bankholderid = this.$route.query.bankholderid
        this.onQuery()
      }
    },
    async getBankAccountDetail(bankholderid){
      this.detailBankAccount = await Utils.getBankAccountDetail(bankholderid, this.bankAccount, false, true);
    }
  },
  async created(){
    await this.getBankAccount()
    this.getQuery()
  }
};
</script>

<style lang="scss">
.container-statement{
  .statement-date,
  .statement-balance{
    font-size: 12px;
    color: #979797;
  }
  .statement-description{
    font-size: 12px;
  }

  .el-table .line-break .cell {
    word-break: break-word;
  }

  .el-table__expand-icon {
    margin-right: 10px;
    background-color: #c4cdd7;
    border-radius: 30px;
    font-size:14px;
}
}
</style>

