import request from './request'
import {uuid} from 'vue-uuid'
import Utils from '../utils'
import Auth from './auth'
const Statement = {
  export(type, query, filename){
    const baseURL = (process.env.VUE_APP_SERVER_BASE_API || "") + process.env.VUE_APP_SERVER_BASE_API_VERSION
    const link = document.createElement('a')
    query.token = Auth.getToken()
    link.href = `${baseURL}/v1/statement/export/${type}${Utils.obj2query(query)}`;
    if(filename){
      link.download = filename
    }
    link.click()
  },
  getStatement(data){
    delete data.token;
    data.idempotency = uuid.v1()
    return request({
      url: `/v1/statement`,
      method: "post",
      data: data
    });
  },
  getSenderInfo(uid){
    return request({
      url: `/v1/statement/senderinfo/${uid}`,
      method: "get"
    });
  },
  sendStatement(query){
    query.token = Auth.getToken()
    return request({
      url: `/v1/statement/export/pdf${Utils.obj2query(query)}`,
      method: "get"
    });
  }
}

export default Statement